import './App.css';
import Grid from './ag-grid';
import Grid2 from './ag-grid2';
import { useState } from 'react';

function App() {
  var [tab,setTab] = useState('1');
  const setValue = (e)=>{
    setTab(e.target.value);
  }

  return (
    <div className='container-fluid'>
       
      
      <ul className="nav nav-tabs tab-panel"  >
        <li className="nav-item" >
          <button className='tab-button nav-link' onClick={setValue} value='1'>Work Log</button>
        </li>
        <li className="nav-item" >
          <button className='tab-button nav-link' onClick={setValue} value='2'>Start of Day</button>
        </li>
        </ul>
        {tab == '1' ? <Grid></Grid> : <Grid2></Grid2>}
    </div>
  );
}

export default App;
