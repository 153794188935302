import React from 'react';

export default (props) => (
  <span
    style={{
      paddingLeft: '0px',
    }}
  >
    {props.value}
  </span>
);