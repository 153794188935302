

import React, { useRef, useState, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import ChecklistRenderer from './ChecklistRenderer';

const Grid2 = () => {
    const gridRef = useRef();
    ModuleRegistry.registerModules([
        RangeSelectionModule,
        ClipboardModule,
    ]);

    const addRow = useCallback(() => {
        rowData = rowData.concat([{ mailTO: "", dos: "", ll: "", lu: "", ls: "", ait: "", ob: "", ib: "", dst: '', ltr: '', ros: '', notes: '', list: { x: true, y: false, z: true } }])
        setRowData(rowData);
    }, []);

    const addMultipleRow = useCallback(() => {
        if(rowsValue == null || rowsValue == undefined){
            return;
        }
        for (let i = 0; i < rowsValue; i++) {
            rowData = rowData.concat([{ mailTO: "", dos: "", ll: "", lu: "", ls: "", ait: "", ob: "", ib: "", dst: '', ltr: '', ros: '', notes: '', list: { x: true, y: false, z: true } }])
        }
        setRowData(rowData);
        document.getElementById('newrows').value = null;
    }, []);

    const addColumn = useCallback(() => {
        if (colvalue === '' || colvalue === null || colvalue === undefined) {
            return;
        }

        var newColumnId = (columnDefs.length + 1) + '-id';
        columnDefs = columnDefs.concat([{ headerName: colvalue, field: newColumnId, width: 220, sortable: true }])
        rowData = rowData.map((x) => {
            x[newColumnId] = '';
            return x;
        })
        setColumnData(columnDefs);
        setRowData(rowData);
        colvalue = "";
        // console.log(columnDefs,rowData)
    }, []);

    const checklistGetter = params => {

        let total = 0;
        Object.entries(params.node.data.list).map((x, y) => {
            total = x[1] ? total + 1 : total;
        })
        return total
    }

    var [rowData, setRowData] = useState([
        { mailTO: "tday@pfplogistics.com, Carlisleleads@pfplogistics.com", dos: "12/5/22", ll: 10, lu: 7, ls: null, ait: 1, ob: 10, ib: 11, dst: 'forkilift safety', ltr: null, ros: null, notes: '1 NTW inbound', list: { x: true, y: false, z: true } },
        { mailTO: "Icleveland@pfplogistics.com", dos: "12/6/22", ll: 10, lu: 7, ls: null, ait: 0, ob: 10, ib: 11, dst: 'Stretching', ltr: null, ros: null, notes: null, list: { x: true, y: false, z: true } },
        { mailTO: "tday@pfplogistics.com, Carlisleleads@pfplogistics.com", dos: "12/7/22", ll: 12, lu: 9, ls: null, ait: 0, ob: 18, ib: 11, dst: 'Stretching', ltr: null, ros: null, notes: "2 NTW's", list: { x: true, y: true, z: true } },
        { mailTO: "tday@pfplogistics.com, Carlisleleads@pfplogistics.com", dos: "12/7/22", ll: null, lu: null, ls: null, ait: null, ob: null, ib: 11, dst: null, ltr: null, ros: null, notes: null, list: { x: false, y: false, z: false } },
        { mailTO: "tday@pfplogistics.com, Carlisleleads@pfplogistics.com", dos: "12/12/22", ll: 10, lu: 7, ls: null, ait: 0, ob: 18, ib: 14, dst: 'Forklifts', ltr: null, ros: null, notes: 'I NTW Truck', list: { x: true, y: false, z: true } },
        { mailTO: "tday@pfplogistics.com, Carlisleleads@pfplogistics.com", dos: "12/13/22", ll: 8, lu: 11, ls: 6, ait: 0, ob: 15, ib: 13, dst: 'Workplace Violance', ltr: null, ros: null, notes: 'Testing SOD', list: { x: false, y: false, z: true } },
        { mailTO: "tday@pfplogistics.com, Carlisleleads@pfplogistics.com", dos: "12/14/22", ll: 8, lu: 8, ls: 5, ait: 0, ob: 8, ib: 12, dst: 'Lifting technique', ltr: null, ros: null, notes: '1 second test', list: { x: true, y: true, z: true } },
    ]);

    var [columnDefs, setColumnData] = useState([
        { headerName: 'Email To', field: 'mailTO', width: 250, sortable: true, rowDrag: true },
        { headerName: 'Date of Summary', field: 'dos', sortable: true, type: ['dateColumn'] },
        { headerName: 'Local Loaders', field: 'll', sortable: true },
        { headerName: 'Local Unloaders', field: 'lu', sortable: true },
        { headerName: 'Local Scanners', field: 'ls', sortable: true },
        { headerName: 'Associates in Training', field: 'ait', sortable: true },
        { headerName: 'Outbounds', field: 'ob' },
        { headerName: 'Inbounds', field: 'ib' },
        { headerName: 'Daily Safety Topic', field: 'dst' },
        { headerName: "Load Today's Roster", field: 'ltr', sortable: true },
        { headerName: 'Roster', field: 'ros', sortable: true },
        {
            headerName: 'Notes', field: 'notes', sortable: true, cellEditorPopup: true,
            cellEditor: 'agLargeTextCellEditor',
            flex: 2,
        },
        // {
        //     headerName: 'Checklist', field: 'list', sortable: true, valueGetter: checklistGetter,
        //     cellRenderer: ChecklistRenderer,
        // },

    ])

    const defaultColGroupDef = {};

    const defaultColDef = {
        flex: 1,
        // set every column width
        width: 100,
        // make every column editable
        editable: true,
        // make every column use 'text' filter by default
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        // make columns resizable
        resizable: true,
    };

    const columnTypes = {
        nonEditableColumn: { editable: true },
        dateColumn: {
            filter: 'agDateColumnFilter',
            // filterParams: { comparator: myDateComparator },
            suppressMenu: true
        }
    };

    const valueChanges = (e) => {
        colvalue = e.target.value;
    }

    const valueChangesRow = (e) => {
        rowsValue = e.target.value;
    }

    const exportAsCSV = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const exportAsExcel = useCallback(() => {
        gridRef.current.api.exportDataAsExcel();
    }, []);

    var { colvalue, setColValue } = useState();
    var { rowsValue, setRowsValue } = useState();
    
    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const onGridReady = useCallback((params) => {
        setTimeout(() => {
            autoSizeAll(false);
            document.getElementsByClassName('ag-container')[0].addEventListener('keydown', function(evt) {
                if(evt.key === 'Enter') {
                    var currentCell = gridOptions.api.getFocusedCell();

                    // If we are editing the last row in the grid
                    gridOptions.api.stopEditing();
                    gridOptions.api.clearFocusedCell();
                    if (currentCell.rowIndex === rowData.length -1) {
                        addRow();
                        setTimeout(() => {
                            gridOptions.api.startEditingCell({
                                rowIndex: rowData.length-1,
                                colKey: currentCell.column.colId
                            });
                            // gridOptions.api.ensureIndexVisible(rowData.length-1, 'bottom')
                        }, 500);
                    }
                    else{
                        gridOptions.api.startEditingCell({
                            rowIndex: currentCell.rowIndex + 1,
                            colKey: currentCell.column.colId
                        });
                    }
                }
              });
        }, 500);
    }, []);

    const gridOptions = {
        enableCharts:true,
        onGridReady:onGridReady,
        rowSelection:'multiple' 
    }

    return (
        <div style={{ width: '100%', height: '100%;' }}>
            <div className="ag-theme-alpine tab-height" style={{ width: '100%', height: '100%;' }}>
                <div className='ag-search-header'>
                    <div>
                        <input type="text" className='form-control' value={colvalue} id="newCol" onChange={valueChanges} placeholder="Write Column Name"></input>
                        <button className='btn btn-bordered ml-2' onClick={addColumn}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg> Add Column</button>

                    </div>
                    <div>
                        <button className='btn btn-bordered ml-2' onClick={exportAsCSV}  > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
                        </svg>Export CSV</button>
                        <button className='btn btn-bordered ml-2' onClick={exportAsExcel}  > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-spreadsheet" viewBox="0 0 16 16">
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                        </svg>Export Excel</button>
                    </div>
                </div>
                <AgGridReact
                    className="ag-container"
                    gridOptions={gridOptions}
                    style={{ width: '100%', height: '100%;' }}
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    defaultColGroupDef={defaultColGroupDef}
                    animateRows={true}
                    columnTypes={columnTypes}
                    suppressDragLeaveHidesColumns={true}
                    enableRangeSelection={true}
                    rowDragManaged={true}
                >
                </AgGridReact>
                <button onClick={addRow} className='btn btn-bordered mt-2'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> Add Row</button>
                <span className='seperator'>|</span>
                <input className='form-control' type="number" value={rowsValue} id="newrows" onChange={valueChangesRow} placeholder="How many rows needed ?"></input>
                <button onClick={addMultipleRow} className='btn btn-bordered mt-2'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg> Add Multiple Rows</button>
            </div>
        </div>
    );
};

export default Grid2;